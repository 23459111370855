<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>{{ $t('Detailed Report') }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left/>
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items"/>
        <h2>
          {{ repr }}
          <b-link @click="show_modal  = true">
            <b-icon-gear/>
          </b-link>
          <a :href="build_url(true, true)">
            <b-icon-download/>
          </a>
        </h2>
        <b-modal hide-header hide-footer v-model="show_modal">
          <h-c-d-report-form @ok="go_to"/>
        </b-modal>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <b-row v-for="(quiz, i) in resp" :key="i">
        <b-col>
          <b-table-simple>
            <colgroup>
              <col/>
              <col/>
              <col/>
            </colgroup>
            <col/>
            <col/>
            <b-tr class="blue-bg">
              <th colspan="5">{{ category ? category.name : 'Undef' }}</th>
            </b-tr>
            <b-tr class="lightblue-bg">
              <th colspan="2">{{ get_info_type_from_quiz(quiz, 'line') }}</th>
              <th>{{ $t('Operator') }}: {{ get_person_name(quiz) }}</th>
              <th>{{ $t('Date') }}: {{ $route.query.start }}</th>
              <th>{{ $t('Controller') }} {{ get_person_name(quiz, 'person') }}</th>
            </b-tr>
            <b-tr class="green-bg">
              <th>№</th>
              <th colspan="2">{{ $t('DMS key element') }}</th>
              <th>{{ $t('Score') }}</th>
              <th>{{ $t('Comment') }}</th>
            </b-tr>
            <b-tr v-for="(answer) in sortAnswers(quiz.answers)" :key="answer.pk">
              <b-td class="lightgreen-bg">{{ answer.order }}</b-td>
              <b-td colspan="2">{{ get_name(answer) }}</b-td>
              <b-td>{{ answer.value |human_value }}</b-td>
              <b-td>{{ answer.commentary }}</b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="3">{{ $t('Total') }}</b-td>
              <b-td class="nowrap">{{ (quiz.p_score * 100).toFixed(1) }} %</b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr class="orange-bg" v-if="quiz.answers.filter(a=>a.action).length">
              <b-th colspan="3">{{ $t('Action Step') }}</b-th>
              <b-th>{{ $t('Deadline') }}</b-th>
              <b-th>{{ $t('Owner') }}</b-th>
            </b-tr>
            <b-tr class='lightpink-bg' v-for="answer in quiz.answers.filter(a=>a.action)" :key="answer.pk">
              <b-td colspan="3">{{ answer.action }}</b-td>
              <b-td>{{ answer.deadline|date_format }}
              </b-td>
              <b-td>{{ info_name(answer.owner) }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-if="is_empty">
        <h2>{{ $t('Nothing found') }}</h2>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import {orderBy, merge} from 'lodash-es'
import {dict_to_search, to_query, date_format} from "../shared";
import HCDReportForm from "../components/HCDReport";
import {SERVER} from "../constants";


export default {
  name: "HCDReport",
  components: {HCDReportForm},
  filters: {
    date_format,
    human_value: value => {
      let ret = '-'
      if (value === true) {
        ret = '1';
      } else if (value === false) {
        ret = '0'
      }
      return ret
    },

  },
  mounted() {
    this.load()
  },
  data() {
    return {
      resp: [],
      loading: false,
      show_modal: false
    }
  },
  computed: {
    br_items() {
      return [
        {text: this.$t('Home'), to: {name: 'Home'}},
        {text: this.$t('Reports'), to: {name: "Reports"}},
        {text: this.$t('HCD Report'), active: true}
      ]
    },
    ...['person', 'operator', 'line', 'cell'].reduce((r, key) => {
      function getter() {
        return this.$store.getters[key].reduce((r1, item) => ({...r1, [item.pk]: item}), {})
      }

      r[key] = getter
      return r
    }, {}),
    repr() {
      let ret = []
      const {category, info, line} = this.$route.query
      if (category) {
        let c = this.$store.getters.category.find(o => o.pk == category)
        if (c) {
          ret.push(c.name)
        }
      } else {
        ret.push(this.$t('All categories'))
      }
      if (info) {
        let c = this.$store.getters.additional_info.find(o => o.pk == info)
        if (c) {
          ret.push(c.name)
        }
      }
      if (line) {
        let c = this.$store.getters.additional_info.find(o => o.pk == line)
        if (c) {
          ret.push(c.name)
        }
      }
      return ret.join(', ')
    },
    is_empty() {
      return this.resp.length == 0
    },
    category() {
      return this.$store.getters.category.find(c => c.pk === parseInt(this.$route.query.category))
    },

  },
  methods: {
    get_name(question, key = 'name') {
      const lang = this.$store.getters.lang || 'en';
      const value = question[key] || {};
      return value[lang] || value['en'] || value['ru'] || this.$t('Unknown')
    },
    get_info_type_from_quiz(quiz, type = 'line') {
      const info = quiz.info || []
      const obj = Object.values(this[type]).find(t => info.indexOf(t.pk) > -1) || {}
      return obj.name || '--'

    },
    build_url(xls = false, with_server = false) {
      let q_data = merge({}, this.$route.query)
      if (xls) {
        q_data['frmt'] = 'xlsx'
      }
      let query_str = dict_to_search(q_data);
      let ret = `api/reports/hcd/?${query_str}`
      if (with_server) {
        ret = `${SERVER}/${ret}`
      }
      return ret
    },
    load() {
      this.loading = true

      Promise.all([
        this.$store.dispatch('load_data', ['question', 'additional_info']),
        this.$store.dispatch("get", {
          url: this.build_url(),
        })
        .then((r) => (this.resp = r))
      ]).then(() => this.loading = false)
    },
    go_to(args) {
      this.show_modal = false
      this.$router.push({name: this.$route.name, query: to_query(args)})
      this.load()
    },
    sortAnswers: answers => orderBy(answers, ['order']),
    info_name(pk) {
      let ret = '--'
      const info = this.$store.getters.additional_info.find(i => i.pk == pk)
      if (info) {
        ret = info.name
      }
      return ret
    },
    get_person_name(quiz, type = 'operator') {
      const infos = this.$store.getters.additional_info.filter(i => quiz.info.indexOf(i.pk) >= 0)
      const person = infos.find(i => i.type === type)
      return person ? person.name : '--'
    }
  },
}
</script>

<style scoped>

</style>
